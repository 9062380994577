<template>
    <div class="index_all" v-loading="loading">
        <div class="type_tab">
            <div :class="{item:true,active:type===-1}" @click="typeChange(-1)">全部项目</div>
            <div :class="{item:true,active:type===1}" @click="typeChange(1)">新建项目</div>
            <div :class="{item:true,active:type===2}" @click="typeChange(2);">续建项目</div>
        </div>
        <ul class="number_box">
            <li class="number_item" v-for="(v,k) in numberList" :key="'parent'+k">
                <div class="left" :style="{backgroundColor:v.color}">
                    <div class="total">
                        <span class="num">{{  v.count5 ?? v.number  }}</span>
                        <span class="unit">{{ v.count5 != undefined ? "个" : "万元" }}</span>
                    </div>
                    <span class="title">{{ v.count5 != undefined ? "总项目数" : v.title }}</span>
                    <span class="other" v-if="v.count5!=undefined">合并项目{{ v.number }}个</span>
                </div>

                <div class="right">
                    <div class="children_item" v-for="(item,index) in v.children" :key="item.title+k">
                        <div class="number" :style="{color:v.color}">{{ item.number }}</div>
                        <div class="title">{{ item.title }}</div>
                    </div>
                </div>
                
                <!-- <div class="total" :style="{backgroundColor:v.color}">
                    <div class="number">
                        <span v-if="v.count5!==undefined">总项目</span>
                        <span class="num">{{ v.number }}</span>
                        <span v-if="v.count5!==undefined">个</span>
                    </div>
                    <div class="title"
                         :style="{fontSize:v.number!==undefined?'14px':'24px',fontWeight:v.number?'normal':'700'}">
                        {{ v.title }}123<span v-if="v.count5!==undefined">（{{ v.count5 }}）</span>
                    </div>
                </div>
                <div class="children">
                    <div class="children_item" v-for="(item,index) in v.children" :key="item.title+k">
                        <div class="number" :style="{color:v.color}">{{ item.number }}</div>
                        <div class="title">{{ item.title }}</div>
                    </div>
                </div> -->
            </li>
        </ul>
        <div class="index_tab_box">
            <div class="total_title" v-show="index_tab===1">
                {{ year }}年度上海援青项目共{{ numberList[0].count5 }}个
            </div>
            <div class="total_title" v-show="index_tab===2">
                {{ year }}年度上海援青资金共{{ numberList[1].number }}万元
            </div>
            <!-- <div class="index_tab"> -->
                <!-- <div :class="{tab_item:true,active:index_tab===1}" @click="indexTabChange(1)">统计项目数量</div>
                <div :class="{tab_item:true,active:index_tab===2}" @click="indexTabChange(2)">统计资金分布</div> -->
            <!-- </div> -->
        </div>
        <div class="chart_static" v-if="index_tab===1">
            <div class="chart_item chart_100px">
                <div class="info">
                    <div class="title">按区域划分</div>
                    <div class="item_box">
                        <div class="item" v-for="(item,index) in chart1" :key="index"
                             :class="{can_click,no_click:item.value==0}" @click="toProjectList(item,{area:item.area})">
                            <div class="name">{{ item.name }}</div>
                            <div class="color" :style="{backgroundColor:item.color}"></div>
                            <div class="value">{{ item.value }}个</div>
                            <!-- <span class="value">{{ (item.money / 10000).toFixed(0)}}万元</span>
                            <span class="value">{{ getChartProportion(item.money,chart1) }}%</span> -->
                        </div>
                    </div>
                </div>
                <div class="chart" id="chart1"></div>
            </div>
            <div class="chart_item chart_150px">
                <div class="info">
                    <div class="title">按类别划分</div>
                    <div class="item_box">
                        <div class="item" v-for="(item,index) in chart2" :key="index"
                             :class="{can_click,no_click:item.value==0}"
                             @click="toProjectList(item,{project_genre:item.project_genre})">
                            <div class="name">{{ item.name }}</div>
                            <div class="color" :style="{backgroundColor:item.color}"></div>
                            <div class="value">{{ item.value }}个</div>
                            <!-- <span class="value">{{ (item.money / 10000).toFixed(0)}}万元</span>
                            <span class="value">{{ getChartProportion(item.money,chart2) }}%</span> -->
                        </div>
                    </div>
                </div>
                <div class="chart" id="chart2"></div>
            </div>
            <div class="chart_item chart_150px">
                <div class="info">
                    <div class="title">按性质划分</div>
                    <div class="item_box">
                        <div class="item" v-for="(item,index) in chart3" :key="index"
                             :class="{can_click,no_click:item.value==0}"
                             @click="toProjectList(item,{project_quality:item.project_quality})">
                            <div class="name">{{ item.name }}</div>
                            <div class="color" :style="{backgroundColor:item.color}"></div>
                            <div class="value">{{ item.value }}个</div>
                            <!-- <span class="value">{{ (item.money / 10000).toFixed(0)}}万元</span>
                            <span class="value">{{ getChartProportion(item.money,chart3) }}%</span> -->
                        </div>
                    </div>
                </div>
                <div class="chart" id="chart3"></div>
            </div>
            <div class="chart_item chart_120px">
                <div class="info">
                    <div class="title">按状态划分 <span style="font-weight: bold">总完工数：{{
                            chart4_finish_total
                        }}个</span></div>
                    <div class="item_box">
                        <div class="item" v-for="(item,index) in chart4" :key="index"
                             :class="{can_click,no_click:item.value==0}"
                             @click="toProjectList(item,{project_status:item.project_status})">
                            <div class="name">{{ item.name }}</div>
                            <div class="color" :style="{backgroundColor:item.color}"></div>
                            <div class="value">{{ item.value }}个</div>
                            <!-- <span class="value">{{ (item.money / 10000).toFixed(0)}}万元</span>
                            <span class="value">{{ getChartProportion(item.money,chart4) }}%</span> -->
                        </div>
                    </div>
                </div>
                <div class="chart" id="chart4"></div>
            </div>
        </div>
        <!-- <div class="chart_static" v-if="index_tab===2">
            <div class="chart_item chart_100px">
                <div class="info">
                    <div class="title">按区域划分</div>
                    <div class="item_box">
                        <div class="item" v-for="(item,index) in chart5" :key="index"
                             :class="{can_click,no_click:item.value==0}" @click="toProjectList(item,{area:item.area})">
                            <div class="name">{{ item.name }}</div>
                            <div class="color" :style="{backgroundColor:item.color}"></div>
                            <div class="value">{{ item.value }}万元</div>
                        </div>
                    </div>
                </div>
                <div class="chart" id="chart5"></div>
            </div>
            <div class="chart_item chart_150px">
                <div class="info">
                    <div class="title">按类别划分</div>
                    <div class="item_box">
                        <div class="item" v-for="(item,index) in chart6" :key="index"
                             :class="{can_click,no_click:item.value==0}"
                             @click="toProjectList(item,{project_genre:item.project_genre})">
                            <div class="name">{{ item.name }}</div>
                            <div class="color" :style="{backgroundColor:item.color}"></div>
                            <div class="value">{{ item.value }}万元</div>
                        </div>
                    </div>
                </div>
                <div class="chart" id="chart6"></div>
            </div>
            <div class="chart_item chart_150px">
                <div class="info">
                    <div class="title">按性质划分</div>
                    <div class="item_box">
                        <div class="item" v-for="(item,index) in chart7" :key="index"
                             :class="{can_click,no_click:item.value==0}"
                             @click="toProjectList(item,{project_quality:item.project_quality})">
                            <div class="name">{{ item.name }}</div>
                            <div class="color" :style="{backgroundColor:item.color}"></div>
                            <div class="value">{{ item.value }}万元</div>
                        </div>
                    </div>
                </div>
                <div class="chart" id="chart7"></div>
            </div>
            <div class="chart_item chart_120px">
                <div class="info">
                    <div class="title">按状态划分 <span style="font-weight: bold">总完工数：{{
                            chart4_finish_total
                        }}个</span></div>
                    <div class="item_box">
                        <div class="item" v-for="(item,index) in chart8" :key="index"
                             :class="{can_click,no_click:item.value==0}"
                             @click="toProjectList(item,{project_status:item.project_status})">
                            <div class="name">{{ item.name }}</div>
                            <div class="color" :style="{backgroundColor:item.color}"></div>
                            <div class="value">{{ item.value }}万元</div>
                        </div>
                    </div>
                </div>
                <div class="chart" id="chart8"></div>
            </div>
        </div> -->
    </div>
</template>

<script>
import api from '@/api/methods/index';
import chart from '@/mixins/chart.js';

export default {
    mixins: [ chart],
    props:{
        area:{
            type:Number,
        },
        year:{
            type:String,
        }
    },
    data() {
        return {
            index_tab: 1,
            type: -1,//类型1：新建 2：续建
            numberList: [
                {
                    title: `年度子项目数`,
                    number: 0,
                    color: '#3396fb',
                    children: [
                        {title: '项目开工率', number: 0},
                        {title: '本年项目完工率', number: 0},
                        {title: '跨年项目完工率', number: 0},
                        // {title:'项目完工率', number:0},
                        // {title:'项目变更率', number:0},
                        // {title:'终止项目', number:0}
                    ],
                    count5: 0
                },
                {
                    title: '总资金 ( 万元 )',
                    number: 0,
                    color: '#07ba3d',
                    children: [
                        {title: '年度投资完成率', number: 0},
                        // {title:'上海资金已到位', number:0},
                        {title: '资金已执行', number: 0},
                        {title: '资金执行率', number: 0},
                        {title: '资金结余', number: 0}
                    ]
                },
            ],
            chart1: [
                {value: 0, name: '州本级', color: '#f7a35c', area: 1 , money: 0},
                {value: 0, name: '玛沁县', color: '#8085e9', area: 2 , money: 0},
                {value: 0, name: '甘德县', color: '#7cb5ec', area: 5 , money: 0},
                {value: 0, name: '达日县', color: '#434348', area: 6 , money: 0},
                {value: 0, name: '班玛县', color: '#e4d354', area: 4 , money: 0},
                {value: 0, name: '久治县', color: '#f15c80', area: 3 , money: 0},
                {value: 0, name: '玛多县', color: '#90ed7d', area: 7 , money: 0}
            ],
            chart2: [
                {value: 0,money:0, name: '保障和改善民生', color: '#f7a35c', project_genre: 1},
                {value: 0,money:0, name: '文化教育支援', color: '#8085e9', project_genre: 2},
                {value: 0,money:0, name: '产业支援促进就业', color: '#f15c80', project_genre: 3},
                {value: 0,money:0, name: '智力支援', color: '#e4d354', project_genre: 4},
                {value: 0,money:0, name: '交往交流交融', color: '#7cb5ec', project_genre: 5},
                // {value:0,money:0,name:'基层组织和政权建设',color:'#90ed7d'},
            ],
            chart3: [
                {value: 0,money:0, name: '固定资产投资类', color: '#f7a35c', project_quality: 1},
                {value: 0,money:0, name: '非固定资产投资类', color: '#8085e9', project_quality: 2},
                // {value: 0,money:0, name: '其他', color: '#f15c80', project_quality: 3}
            ],
            chart4: [
                {value: 0,money:0, name: '未开工的', color: '#f7a35c', project_status: '1,2'},
                {value: 0,money:0, name: '已经开工', color: '#8085e9', project_status: '3,4'},
                {value: 0,money:0, name: '已经完工', color: '#f15c80', project_status: '5'},
                {value: 0,money:0, name: '竣工验收', color: '#e4d354', project_status: '6'},
                // {value:0,money:0,name:'项目未开始实施',color:'#f7a35c'},
                // {value:0,money:0,name:'前期准备过程中',color:'#8085e9'},
                // {value:0,money:0,name:'项目开始实施',color:'#f15c80'},
                // {value:0,money:0,name:'项目进程过半',color:'#e4d354'},
                // {value:0,money:0,name:'完工未验收',color:'#7cb5ec'},
                // {value:0,money:0,name:'竣工验收',color:'#434348'},
                // {value:0,money:0,name:'终止项目',color:'#90ed7d'},
                // {value:0,money:0,name:'续建项目',color:'#09cbdc'},
                // {value:0,money:0,name:'变更项目',color:'#da88f1'},
            ],
            chart5: [
                {value: 0, name: '州本级', color: '#f7a35c', area: 1},
                {value: 0, name: '玛沁县', color: '#8085e9', area: 2},
                {value: 0, name: '甘德县', color: '#7cb5ec', area: 5},
                {value: 0, name: '达日县', color: '#434348', area: 6},
                {value: 0, name: '班玛县', color: '#e4d354', area: 4},
                {value: 0, name: '久治县', color: '#f15c80', area: 3},
                {value: 0, name: '玛多县', color: '#90ed7d', area: 7}
            ],
            chart6: [
                {value: 0, name: '保障和改善民生', color: '#f7a35c', project_genre: 1},
                {value: 0, name: '文化教育支援', color: '#8085e9', project_genre: 2},
                {value: 0, name: '产业支援促进就业', color: '#f15c80', project_genre: 3},
                {value: 0, name: '智力支援', color: '#e4d354', project_genre: 4},
                {value: 0, name: '交往交流交融', color: '#7cb5ec', project_genre: 5},
                // {value:0,name:'基层组织和政权建设',color:'#90ed7d'},
            ],
            chart7: [
                {value: 0, name: '固定资产投资类', color: '#f7a35c', project_quality: 1},
                {value: 0, name: '非固定资产投资类', color: '#8085e9', project_quality: 2},
                {value: 0, name: '其他', color: '#f15c80', project_quality: 3}
            ],
            chart8: [
                {value: 0, name: '未开工的', color: '#f7a35c', project_status: '1,2'},
                {value: 0, name: '已经开工', color: '#8085e9', project_status: '3,4'},
                {value: 0, name: '已经完工', color: '#f15c80', project_status: '5'},
                {value: 0, name: '竣工验收', color: '#e4d354', project_status: '6'},
                // {value:0,name:'项目未开始实施',color:'#f7a35c'},
                // {value:0,name:'前期准备过程中',color:'#8085e9'},
                // {value:0,name:'项目开始实施',color:'#f15c80'},
                // {value:0,name:'项目进程过半',color:'#e4d354'},
                // {value:0,name:'完工未验收',color:'#7cb5ec'},
                // {value:0,name:'竣工验收',color:'#434348'},
                // {value:0,name:'终止项目',color:'#90ed7d'},
                // {value:0,name:'续建项目',color:'#09cbdc'},
                // {value:0,name:'变更项目',color:'#da88f1'},
            ],
           
            chart4_finish_total: 0,//总完工数
        };
    },
    computed: {
        first_page_power() {
            return this.$store.state.user.current_user.first_page_power;
        },
        //是否有点击数字跳转项目列表的权限
        can_click() {
            let shi_project_project_power = this.$store.state.user.current_user.shi_project_project_power;
            return shi_project_project_power.power.look == 1 && shi_project_project_power.area == -1;
        },
        // chart4_finish_total() {
        //     let finish1 = this.chart4[2].value || 0;
        //     let finish2 = this.chart4[3].value || 0;
        //     return Number((finish1 + finish2).toFixed(2));
        // },
        // chart8_finish_total() {
        //     let finish1 = this.chart8[2].value || 0;
        //     let finish2 = this.chart8[3].value || 0;
        //     return Number((finish1 + finish2).toFixed(2));
        // }
    },
    created() {
        if (this.$route.query.index_tab) {
            this.index_tab = Number(this.$route.query.index_tab);
        }
        if (this.$route.query.type) {
            this.type = Number(this.$route.query.type);
        }
        if (this.index_tab == 1) {
            this.getProjectNumber();
        }
        if (this.index_tab == 2) {
            this.gettab2Number();
        }
    },
    watch:{
        area() {
            this.areaChange()
        },
        year() {
            this.timeChange()
        }
    },
    methods: {
        // 区域划分占比
        getChartProportion(num,list = []) {
            const total = list.reduce((pre, cur) => pre + cur.money, 0);
            if (total == 0) return 0
            return (num / total * 100).toFixed(2)
        },
        getProjectNumber() {
            this.loading = true;
            this.numberList = [
                {
                    title: `年度子项目数`,
                    number: 0,
                    color: '#3396fb',
                    children: [
                        {title: '项目开工率', number: 0},
                        {title: '本年项目完工率', number: 0},
                        {title: '跨年项目完工率', number: 0},
                        // {title:'项目完工率', number:0},
                        // {title:'项目变更率', number:0},
                        // {title:'终止项目', number:0}
                    ],
                    count5: 0
                },
                {
                    title: '总资金 ( 万元 )',
                    number: 0,
                    color: '#07ba3d',
                    children: [
                        {title: '年度投资完成率', number: 0},
                        // {title:'上海资金已到位', number:0},
                        {title: '资金已执行', number: 0},
                        {title: '资金执行率', number: 0},
                        {title: '资金结余', number: 0}
                    ]
                },
            ];
            this.chart1 = [
                {value: 0, name: '州本级', color: '#f7a35c', area: 1 , money:0},
                {value: 0, name: '玛沁县', color: '#8085e9', area: 2 , money:0},
                {value: 0, name: '甘德县', color: '#7cb5ec', area: 5 , money:0},
                {value: 0, name: '达日县', color: '#434348', area: 6 , money:0},
                {value: 0, name: '班玛县', color: '#e4d354', area: 4 , money:0},
                {value: 0, name: '久治县', color: '#f15c80', area: 3 , money:0},
                {value: 0, name: '玛多县', color: '#90ed7d', area: 7 , money:0}
            ];
            this.chart2 = [
                {value: 0,money:0, name: '保障和改善民生', color: '#f7a35c', project_genre: 1},
                {value: 0,money:0, name: '文化教育支援', color: '#8085e9', project_genre: 2},
                {value: 0,money:0, name: '产业支援促进就业', color: '#f15c80', project_genre: 3},
                {value: 0,money:0, name: '智力支援', color: '#e4d354', project_genre: 4},
                {value: 0,money:0, name: '交往交流交融', color: '#7cb5ec', project_genre: 5},
            ];
            this.chart3 = [
                {value: 0,money:0, name: '固定资产投资类', color: '#f7a35c', project_quality: 1},
                {value: 0,money:0, name: '非固定资产投资类', color: '#8085e9', project_quality: 2},
                // {value: 0,money:0, name: '其他', color: '#f15c80', project_quality: 3}
            ];
            this.chart4 = [
                {value: 0,money:0, name: '未开工的', color: '#f7a35c', project_status: '1,2'},
                {value: 0,money:0, name: '已经开工', color: '#8085e9', project_status: '3,4'},
                {value: 0,money:0, name: '已经完工', color: '#f15c80', project_status: '5'},
                {value: 0,money:0, name: '竣工验收', color: '#e4d354', project_status: '6'},
            ];
            this.chart5 = [
                {value: 0, name: '州本级', color: '#f7a35c', area: 1},
                {value: 0, name: '玛沁县', color: '#8085e9', area: 2},
                {value: 0, name: '甘德县', color: '#7cb5ec', area: 5},
                {value: 0, name: '达日县', color: '#434348', area: 6},
                {value: 0, name: '班玛县', color: '#e4d354', area: 4},
                {value: 0, name: '久治县', color: '#f15c80', area: 3},
                {value: 0, name: '玛多县', color: '#90ed7d', area: 7}
            ];
            this.chart6 = [
                {value: 0, name: '保障和改善民生', color: '#f7a35c', project_genre: 1},
                {value: 0, name: '文化教育支援', color: '#8085e9', project_genre: 2},
                {value: 0, name: '产业支援促进就业', color: '#f15c80', project_genre: 3},
                {value: 0, name: '智力支援', color: '#e4d354', project_genre: 4},
                {value: 0, name: '交往交流交融', color: '#7cb5ec', project_genre: 5},
            ];
            this.chart7 = [
                {value: 0, name: '固定资产投资类', color: '#f7a35c', project_quality: 1},
                {value: 0, name: '非固定资产投资类', color: '#8085e9', project_quality: 2},
                {value: 0, name: '其他', color: '#f15c80', project_quality: 3}
            ];
            this.chart8 = [
                {value: 0, name: '未开工的', color: '#f7a35c', project_status: '1,2'},
                {value: 0, name: '已经开工', color: '#8085e9', project_status: '3,4'},
                {value: 0, name: '已经完工', color: '#f15c80', project_status: '5'},
                {value: 0, name: '竣工验收', color: '#e4d354', project_status: '6'},
            ];
            api.home.getProjectNumber({year: this.year, type: this.type, area: this.area}).then(res => {
                if (res.data) {
                    if (res.data.rows) {
                        let rows = res.data.rows;
                        /*if(rows.project_num){
                            this.numberList[0].number=rows.project_num.count1;
                            this.numberList[0].children[0].number=rows.project_num.count2;
                            this.numberList[0].children[1].number=rows.project_num.count3;
                            this.numberList[0].children[2].number=rows.project_num.count4;
                        }*/
                        if (rows.project_num) {
                            this.numberList[0].number = rows.project_num.count1 || 0;
                            this.numberList[0].count5 = rows.project_num.count5 || 0;
                        }
                        if (rows.evolve) {
                            this.numberList[0].children[0].number = rows.evolve.start_rate ? Math.round(Number(rows.evolve.start_rate) * 100) + '%' : 0;
                            this.numberList[0].children[1].number = rows.evolve.year_finish_rate ? Math.round(Number(rows.evolve.year_finish_rate) * 100) + '%' : 0;
                            if (rows.evolve.all_year_finish_rate) {
                                rows.evolve.all_year_finish_rate = Number(rows.evolve.all_year_finish_rate)
                            }
                            this.numberList[0].children[2].number = rows.evolve.all_year_finish_rate ? Math.round(Number(rows.evolve.all_year_finish_rate) * 100) + '%' : '/';
                            // this.numberList[0].children[1].number=Math.round(Number(rows.evolve.finish_rate)*100)+'%';
                            // this.numberList[0].children[2].number=Math.round(Number(rows.evolve.change_rate)*100)+'%';
                            // this.numberList[0].children[3].number=rows.evolve.stop_num;
                        }
                        if (rows.fund) {
                            this.numberList[1].number = rows.fund.year_invest_num ? Number((Number(rows.fund.year_invest_num) / 10000).toFixed(2)) : 0;
                            this.numberList[1].children[0].number = rows.fund.year_fund_rate ? Math.round(Number(rows.fund.year_fund_rate) * 100) + '%' : 0;
                            // this.numberList[1].children[0].number=Number(rows.fund.qr_fund)/10000;
                            let dotIndex = (Number((Number(rows.fund.project_fund_app) / 10000).toFixed(2))).toString().indexOf('.');
                            if (dotIndex === -1) {
                                this.numberList[1].children[1].number = rows.fund.project_fund_app ? Number((Number(rows.fund.project_fund_app) / 10000).toFixed(2)) : 0;
                            } else {
                                this.numberList[1].children[1].number = rows.fund.project_fund_app ? (Number((Number(rows.fund.project_fund_app) / 10000).toFixed(2))).toString().slice(0, dotIndex + 3) : 0;
                            }
                            this.numberList[1].children[2].number = rows.fund.fund_rate ? Math.round(Number((Number(rows.fund.fund_rate) * 100).toFixed(2))) + '%' : 0;
                            if (rows.fund.jieyu) {
                                rows.fund.jieyu = Number(rows.fund.jieyu)
                            }
                            this.numberList[1].children[3].number = rows.fund.jieyu ? Number((Number(rows.fund.jieyu) / 10000).toFixed(2)) : '/';
                        }
                        /*if(rows.evolve){
                            this.numberList[2].children[0].number=Math.round(Number(rows.evolve.start_rate)*100)+'%';
                            this.numberList[2].children[1].number=Math.round(Number(rows.evolve.finish_rate)*100)+'%';
                            this.numberList[2].children[2].number=Math.round(Number(rows.evolve.change_rate)*100)+'%';
                            this.numberList[2].children[3].number=rows.evolve.stop_num;
                        }*/
                        if (rows.area) {
                            rows.area.forEach(item => {
                                this.chart1.forEach(chart => {
                                    if (chart.name === item.name) {
                                        chart.value = item.num;
                                        chart.money = item.money;
                                    }
                                });
                            });
                        }
                        if (rows.qenre) {
                            rows.qenre.forEach(item => {
                                this.chart2.forEach(chart => {
                                    if (chart.name === item.name) {
                                        chart.value = item.num;
                                        chart.money = item.money;
                                    }
                                });
                            });
                        }
                        if (rows.quality) {
                            rows.quality.forEach(item => {
                                this.chart3.forEach(chart => {
                                    if (chart.name === item.name) {
                                        chart.value = item.num;
                                        chart.money = item.money;
                                    }
                                });
                            });
                        }
                        if (rows.status) {
                            let num1 = rows.status.filter(item => item.name === '项目未开始实施' || item.name === '前期准备过程中');//未开工的
                            let num2 = rows.status.filter(item => item.name === '项目开始实施' || item.name === '项目进程过半');//已经开工
                            let num3 = rows.status.filter(item => item.name === '完工未验收');//完工
                            let num4 = rows.status.filter(item => item.name === '竣工验收');//竣工验收
                            num1.forEach(n => {
                                this.chart4[0].value += +n.num;
                                this.chart4[0].money += +n.money;
                            });
                            num2.forEach(n => {
                                this.chart4[1].value += +n.num;
                                this.chart4[1].money += +n.money;
                            });
                            num3.forEach(n => {
                                this.chart4[2].value += +n.num;
                                this.chart4[2].money += +n.money;
                            });
                            num4.forEach(n => {
                                this.chart4[3].value += +n.num;
                                this.chart4[3].money += +n.money;
                            });
                            let finish1 = this.chart4[2].value || 0;
                            let finish2 = this.chart4[3].value || 0;
                            this.chart4_finish_total = Number((finish1 + finish2).toFixed(2));
                            // rows.status.forEach(item=>{
                            //     this.chart4.forEach(chart=>{
                            //         if(chart.name===item.name){
                            //             chart.value=item.num;
                            //         }
                            //     })
                            // })
                        }
                    }
                }
                this.numberList[0].title = `${this.year}年度子项目数`;
                this.loading = false;

                const chart1_total = this.chart1.reduce((pre, cur) => pre + cur.value, 0);
                const chart2_total = this.chart2.reduce((pre, cur) => pre + cur.value, 0);
                const chart3_total = this.chart3.reduce((pre, cur) => pre + cur.value, 0);
                const chart4_total = this.chart4.reduce((pre, cur) => pre + cur.value, 0);
                this.setChart('chart1',{
                    trigger: "item",
				    formatter: (data) => {
                        return `
                            ${data.marker}${data.name}<br/>
                            项目数量：${data.data.value}<br/>
                            数量占比：${ chart1_total ? ((data.data.value / chart1_total * 100).toFixed(2)) : 0}%<br/>
                            投资金额：${(data.data.money / 10000).toFixed(2)}万元<br/>
                            投资占比：${this.getChartProportion(data.data.money,this.chart1)}%
                        `
                    }
                });
                this.setChart('chart2',{
                    trigger: "item",
				    formatter: (data) => {
                        return `
                            ${data.marker}${data.name}<br/>
                            项目数量：${data.data.value}<br/>
                            数量占比：${ chart2_total ? ((data.data.value / chart2_total * 100).toFixed(2)) : 0}%<br/>
                            投资金额：${(data.data.money / 10000).toFixed(2)}万元<br/>
                            投资占比：${this.getChartProportion(data.data.money,this.chart2)}%
                        `
                    }
                });
                this.setChart('chart3',{
                    trigger: "item",
				    formatter: (data) => {
                        return `
                            ${data.marker}${data.name}<br/>
                            项目数量：${data.data.value}<br/>
                            数量占比：${ chart3_total ? ((data.data.value / chart3_total * 100).toFixed(2)) : 0}%<br/>
                            投资金额：${(data.data.money / 10000).toFixed(2)}万元<br/>
                            投资占比：${this.getChartProportion(data.data.money,this.chart3)}%
                        `
                    }
                });
                this.setChart('chart4',{
                    trigger: "item",
				    formatter: (data) => {
                        return `
                            ${data.marker}${data.name}<br/>
                            项目数量：${data.data.value}<br/>
                            数量占比：${ chart4_total ? ((data.data.value / chart4_total * 100).toFixed(2)) : 0}%<br/>
                            投资金额：${(data.data.money / 10000).toFixed(2)}万元<br/>
                            投资占比：${this.getChartProportion(data.data.money,this.chart4)}%
                        `
                    }
                });
            });
        },
        gettab2Number() {
            this.loading = true;
            this.numberList = [
                {
                    title: `年度子项目数`,
                    number: 0,
                    color: '#3396fb',
                    children: [
                        {title: '项目开工率', number: 0},
                        {title: '本年项目完工率', number: 0},
                        {title: '跨年项目完工率', number: 0},
                        // {title:'项目完工率', number:0},
                        // {title:'项目变更率', number:0},
                        // {title:'终止项目', number:0}
                    ],
                    count5: 0
                },
                {
                    title: '总资金 ( 万元 )',
                    number: 0,
                    color: '#07ba3d',
                    children: [
                        {title: '年度投资完成率', number: 0},
                        // {title:'上海资金已到位', number:0},
                        {title: '资金已执行', number: 0},
                        {title: '资金执行率', number: 0},
                        {title: '资金结余', number: 0}
                    ]
                },
            ];
            this.chart1 = [
                {value: 0, name: '州本级', color: '#f7a35c', area: 1},
                {value: 0, name: '玛沁县', color: '#8085e9', area: 2},
                {value: 0, name: '甘德县', color: '#7cb5ec', area: 5},
                {value: 0, name: '达日县', color: '#434348', area: 6},
                {value: 0, name: '班玛县', color: '#e4d354', area: 4},
                {value: 0, name: '久治县', color: '#f15c80', area: 3},
                {value: 0, name: '玛多县', color: '#90ed7d', area: 7}
            ];
            this.chart2 = [
                {value: 0, name: '保障和改善民生', color: '#f7a35c', project_genre: 1},
                {value: 0, name: '文化教育支援', color: '#8085e9', project_genre: 2},
                {value: 0, name: '产业支援促进就业', color: '#f15c80', project_genre: 3},
                {value: 0, name: '智力支援', color: '#e4d354', project_genre: 4},
                {value: 0, name: '交往交流交融', color: '#7cb5ec', project_genre: 5},
            ];
            this.chart3 = [
                {value: 0, name: '固定资产投资类', color: '#f7a35c', project_quality: 1},
                {value: 0, name: '非固定资产投资类', color: '#8085e9', project_quality: 2},
                {value: 0, name: '其他', color: '#f15c80', project_quality: 3}
            ];
            this.chart4 = [
                {value: 0, name: '未开工的', color: '#f7a35c', project_status: '1,2'},
                {value: 0, name: '已经开工', color: '#8085e9', project_status: '3,4'},
                {value: 0, name: '已经完工', color: '#f15c80', project_status: '5'},
                {value: 0, name: '竣工验收', color: '#e4d354', project_status: '6'},
            ];
            this.chart5 = [
                {value: 0, name: '州本级', color: '#f7a35c', area: 1},
                {value: 0, name: '玛沁县', color: '#8085e9', area: 2},
                {value: 0, name: '甘德县', color: '#7cb5ec', area: 5},
                {value: 0, name: '达日县', color: '#434348', area: 6},
                {value: 0, name: '班玛县', color: '#e4d354', area: 4},
                {value: 0, name: '久治县', color: '#f15c80', area: 3},
                {value: 0, name: '玛多县', color: '#90ed7d', area: 7}
            ];
            this.chart6 = [
                {value: 0, name: '保障和改善民生', color: '#f7a35c', project_genre: 1},
                {value: 0, name: '文化教育支援', color: '#8085e9', project_genre: 2},
                {value: 0, name: '产业支援促进就业', color: '#f15c80', project_genre: 3},
                {value: 0, name: '智力支援', color: '#e4d354', project_genre: 4},
                {value: 0, name: '交往交流交融', color: '#7cb5ec', project_genre: 5},
            ];
            this.chart7 = [
                {value: 0, name: '固定资产投资类', color: '#f7a35c', project_quality: 1},
                {value: 0, name: '非固定资产投资类', color: '#8085e9', project_quality: 2},
                {value: 0, name: '其他', color: '#f15c80', project_quality: 3}
            ];
            this.chart8 = [
                {value: 0, name: '未开工的', color: '#f7a35c', project_status: '1,2'},
                {value: 0, name: '已经开工', color: '#8085e9', project_status: '3,4'},
                {value: 0, name: '已经完工', color: '#f15c80', project_status: '5'},
                {value: 0, name: '竣工验收', color: '#e4d354', project_status: '6'},
            ];
            api.home.getProjectNumber({year: this.year, type: this.type, area: this.area}).then(res => {
                if (res.data) {
                    if (res.data.rows) {
                        let rows = res.data.rows;
                        if (rows.project_num) {
                            this.numberList[0].number = rows.project_num.count1 || 0;
                            this.numberList[0].count5 = rows.project_num.count5 || 0;
                        }
                        if (rows.evolve) {
                            this.numberList[0].children[0].number = rows.evolve.start_rate ? Math.round(Number(rows.evolve.start_rate) * 100) + '%' : 0;
                            this.numberList[0].children[1].number = rows.evolve.year_finish_rate ? Math.round(Number(rows.evolve.year_finish_rate) * 100) + '%' : 0;
                            if (rows.evolve.all_year_finish_rate) {
                                rows.evolve.all_year_finish_rate = Number(rows.evolve.all_year_finish_rate)
                            }
                            this.numberList[0].children[2].number = rows.evolve.all_year_finish_rate ? Math.round(Number(rows.evolve.all_year_finish_rate) * 100) + '%' : '/';
                        }
                        if (rows.fund) {
                            this.numberList[1].number = rows.fund.year_invest_num ? Number((Number(rows.fund.year_invest_num) / 10000).toFixed(2)) : 0;
                            this.numberList[1].children[0].number = rows.fund.year_fund_rate ? Math.round(Number(rows.fund.year_fund_rate) * 100) + '%' : 0;
                            let dotIndex = (Number((Number(rows.fund.project_fund_app) / 10000).toFixed(2))).toString().indexOf('.');
                            if (dotIndex === -1) {
                                this.numberList[1].children[1].number = rows.fund.project_fund_app ? Number((Number(rows.fund.project_fund_app) / 10000).toFixed(2)) : 0;
                            } else {
                                this.numberList[1].children[1].number = rows.fund.project_fund_app ? (Number((Number(rows.fund.project_fund_app) / 10000).toFixed(2))).toString().slice(0, dotIndex + 3) : 0;
                            }
                            this.numberList[1].children[2].number = rows.fund.fund_rate ? Math.round(Number(rows.fund.fund_rate) * 100) + '%' : 0;
                            if (rows.fund.jieyu) {
                                rows.fund.jieyu = Number(rows.fund.jieyu)
                            }
                            this.numberList[1].children[3].number = rows.fund.jieyu ? Number((Number(rows.fund.jieyu) / 10000).toFixed(2)) : '/';
                        }
                        if (rows.area) {
                            rows.area.forEach(item => {
                                this.chart5.forEach(chart => {
                                    if (chart.name === item.name) {
                                        chart.value = item.money ? Number((Number(item.money) / 10000).toFixed(2)) : 0;
                                    }
                                });
                            });
                        }
                        if (rows.qenre) {
                            rows.qenre.forEach(item => {
                                this.chart6.forEach(chart => {
                                    if (chart.name === item.name) {
                                        chart.value = item.money ? Number((Number(item.money) / 10000).toFixed(2)) : 0;
                                    }
                                });
                            });
                        }
                        if (rows.quality) {
                            rows.quality.forEach(item => {
                                this.chart7.forEach(chart => {
                                    if (chart.name === item.name) {
                                        chart.value = item.money ? Number((Number(item.money) / 10000).toFixed(2)) : 0;
                                    }
                                });
                            });
                        }
                        if (rows.status) {
                            let num1 = rows.status.filter(item => item.name === '项目未开始实施' || item.name === '前期准备过程中').map(a => Number((Number(a.money) / 10000).toFixed(2)));//未开工的
                            let num2 = rows.status.filter(item => item.name === '项目开始实施' || item.name === '项目进程过半').map(a => Number((Number(a.money) / 10000).toFixed(2)));//已经开工
                            let num3 = rows.status.filter(item => item.name === '完工未验收').map(a => Number((Number(a.money) / 10000).toFixed(2)));//完工
                            let num4 = rows.status.filter(item => item.name === '竣工验收').map(a => Number((Number(a.money) / 10000).toFixed(2)));//竣工验收
                            num1.forEach(n => {
                                this.chart8[0].value += n;
                            });
                            num2.forEach(n => {
                                this.chart8[1].value += n;
                            });
                            num3.forEach(n => {
                                this.chart8[2].value += n;
                            });
                            num4.forEach(n => {
                                this.chart8[3].value += n;
                            });
                            let num5 = rows.status.filter(item => item.name === '完工未验收').map(a => a.num);//完工
                            let num6 = rows.status.filter(item => item.name === '竣工验收').map(a => a.num);//竣工验收
                            let finish1 = 0;
                            let finish2 = 0;
                            num5.forEach(n => {
                                finish1 += n;
                            });
                            num6.forEach(n => {
                                finish2 += n;
                            });
                            this.chart4_finish_total = Number((finish1 + finish2).toFixed(2));
                            // rows.status.forEach(item=>{
                            //     this.chart8.forEach(chart=>{
                            //         if(chart.name===item.name){
                            //             chart.value=Number(item.money)/10000;
                            //         }
                            //     })
                            // })
                        }
                    }
                }
                this.numberList[0].title = `${this.year}年度子项目数`;
                this.loading = false;
                this.setChart('chart5', {trigger: 'item', formatter: "{b}<br/>项目资金 : {c}万元<br/>总占比 : {d}%"});
                this.setChart('chart6', {trigger: 'item', formatter: "{b}<br/>项目资金 : {c}万元<br/>总占比 : {d}%"});
                this.setChart('chart7', {trigger: 'item', formatter: "{b}<br/>项目资金 : {c}万元<br/>总占比 : {d}%"});
                this.setChart('chart8', {trigger: 'item', formatter: "{b}<br/>项目资金 : {c}万元<br/>总占比 : {d}%"});
            });
        },
        timeChange() {
            if (this.index_tab === 1) {
                this.getProjectNumber();
            } else {
                this.gettab2Number();
            }
        },
        //如果有 查看市项目管理列表的权限 和 所属地区为所有地区 跳转到项目列表去
        toProjectList(item, query = {}) {
            if (item.value == 0) {
                return;
            }
            if (!this.can_click) {
                return;
            }
            let new_query = {
                build_quality: 0,
                is_statistics: 1
            };
            if (!query.area && this.area) {
                new_query.area = this.area;
            }
            this.$router.push({
                path: '/pc/project',
                query: {...query, ...new_query}
            });
        },
        typeChange(type) {
            if (type === this.type) {
                return;
            }
            this.type = type;
            this.timeChange();
            let query = {};
            if (this.$route.query) {
                query = JSON.parse(JSON.stringify(this.$route.query));
            }
            query.type = type;
            this.$router.replace({
                path: '/pc/index/all',
                query
            });
        },
        areaChange() {
            this.timeChange();
            let query = {};
            if (this.$route.query) {
                query = JSON.parse(JSON.stringify(this.$route.query));
            }
            query.area = this.area;
            this.$router.replace({
                path: '/pc/index/all',
                query
            });
        },
        indexTabChange(tab) {
            if (tab === this.index_tab) {
                return;
            }
            this.index_tab = tab;
            if (this.index_tab == 1) {
                this.getProjectNumber();
            }
            if (this.index_tab == 2) {
                this.gettab2Number();
            }
            let query = {};
            if (this.$route.query) {
                query = JSON.parse(JSON.stringify(this.$route.query));
            }
            query.index_tab = tab;
            this.$router.replace({
                path: '/pc/index/all',
                query
            });
        }
    }
};
</script>

<style scoped lang="less">
@import "all.less";
@import "../index_global.less";
</style>